<template>
  <div>
    <NavigationBar titel="Berichte" isPreview></NavigationBar>
  </div>
</template>

<script>
import NavigationBar from "@/components/_systemwide/NavigationBar.vue";
export default {
  name: "accounting-reports",
  components: {
    NavigationBar,
  },
};
</script>
